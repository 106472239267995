<template>
  <div class="smartHr">
    <div style="overflow-y: scroll;height:100%;width:100%" ref="GoTopContent">
      <Title></Title>

      <div class="banner">
        <h4 style="font-size: 46px; font-weight: 600; padding: 0px 0 40px">
          岗位目标动态监测
        </h4>
        <button @click="$router.push('/freeTrial')">立即预约演示</button>
      </div>

      <div class="systemArchitecture">
        <h4 class="descH4">应用框架</h4>
        <span class="line"></span>
        <img src="@/assets/solute/smartXycg/gnt.jpg" alt="" style="width:1080px;margin-top:40px">
      </div>

      <div class="systemValue">
        <h4 class="descH4">业务痛点</h4>
        <span class="line"></span>
        <div class="valueContent">
          <div class="valueItem" v-for="(item,index) in valueList" :key="index">
            <div class="valueItemMask"></div>
            <img :src="item.src" alt="" class="valueLeft">
            <img :src="item.hoverScr" alt="" class="valueLeftHover">
            <div class="valueRight">
              {{item.content}}
            </div>
          </div>
        </div>
      </div>

      <div class="solute">
        <h4 class="descH4">解决方案</h4>
        <span class="line"></span>
        <div class="yetdContent">
          <div v-for="(item,index) in soliteData" :key="index" class="yetdItem">
            <div class="valueItemMask"></div>
            <div class="yetdTitle">{{item.title}}</div>
            <div class="yetdLine"></div>
            <div class="yetdCon">{{item.content}}</div>
          </div>
        </div>
      </div>

      <div class="applicationCase">
        <h4 class="descH4">应用案例</h4>
        <span class="line"></span>
        <div class="appCaseCon">
          <el-carousel indicator-position="outside" class="caseContent" arrow="never">
            <el-carousel-item v-for="(item,index) in caseData" :key="index" class="caseItem">
              <div class="caseLeft">
                <img :src="item.src" alt="" style="width:100%;height:100%">
              </div>
              <div class="caseRight">
                <div class="caseTitle">{{item.title}}</div>
                <div class="caseRIghtCon">{{item.content}}</div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>

      <Foot></Foot>
    </div>
    <BackTop class="backTop"></BackTop>
  </div>
</template>

<script>
export default {
  name: 'SmartXyyjcg',
  data () {
    return {
      valueList: [
        { content: '学院绩效考核工作业务复杂、事务繁多，每个学院考核指标、流程及要求不同，统计工作量大。', src: require('@/assets/solute/SmartCampus/smartOne.png'), hoverScr: require('@/assets/oneHover.png') },
        { content: '教师考核选聘把关不严，考核评价缺乏整体设计，考核结果的科学运用有待完善。', src: require('@/assets/solute/SmartCampus/smartTwo.png'), hoverScr: require('@/assets/towHover.png') },
        { content: '目标监控与考核测评脱钩，绩效体系搭建与岗位目标考核设定不匹配。', src: require('@/assets/solute/SmartCampus/smartThree.png'), hoverScr: require('@/assets/threeHover.png') },
        { content: '数据统计和结果分析困难，人工核算容易出现失误。', src: require('@/assets/solute/SmartCampus/smartFour.png'), hoverScr: require('@/assets/fiveHover.png') }
      ],
      soliteData: [
        { title: '聚焦岗位职责，精细化过程管理', content: '灵活设定岗位职责目标，岗位指标可从指标模板库选择，目标精准管理，确保高效执行落地，让考核有理有据。' },
        { title: '绩效数据智能采集，全流程自动监控', content: '绩效数据自动汇总，业绩指标智能判断，考核流程自动化，业绩材料电子化，保障评价打分结果客观、立体、全面。' },
        { title: '激发教师潜能，提升组织效能', content: '指标拆解细分任务，明确目标计划，高效执行落地，学院绩效与个人绩效关联，牵引教师聚焦学院目标达成。' },
        { title: '构建全景绩效数据看板，敏捷响应学院动态分析需求', content: '基于全校各学院绩效达成分析，预置目标达成、组织效能、人才盘点三大维度分析主题和常用分析指标，赋能决策。' }

      ],
      caseData: [
        { src: require('@/assets/TypicalCase/typeCase/xadzkj.png'), title: '西安电子科技大学', content: '西安电子科技大学院面向学院的全周期业绩成果测算系统于 2021 年开始建设，基于“大平台+子模块+个性化应用”的整体规划、分期建设、逐步实施，持续上线优化的建设策略，目前已经完成了平台整体框架的建设，能面向不同学院建设考核模块，实现个性化指标搭建、申报审核页面及审核流程定义，能同步相关职能部门各业务系统绩效数据，实现定性考核与定量考核相结合的绩效考核业务全流程化管理。业绩成果测算系统，实现了各学院业绩量化核算和绩效分配，提升了不同学院绩效考核业务管理的效率和服务水平。面向学院的全周期业绩成果测算系统，助力西安电子科技大学实现目标过程管理及考核结果管理一体化。' }
        // { src: require('@/assets/home/2023Banner.png'), title: '西安电子科技大学', content: 'crrrrr' }
      ]
    }
  },
  methods: {
    getHeight () {
      if (window.innerWidth <= 800) {
        this.$router.push('/moSlution/moSmartXyyjcg')
      }
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.getHeight)
  },
  created () {
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  }
}
</script>

<style scoped lang='scss'>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.smartHr{
  position: relative;
  top: 0;
  left: 0;
  min-width: 1200px;
  height: 100vh;
  overflow: hidden;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
img {
  object-fit: cover;
}
li {
  list-style: none;
}
.line {
  display: inline-block;
  width: 40px;
  height: 6px;
  background: #d70c0c;
  border-radius: 3px;
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.banner {
  width: 100%;
  height: 460px;
  background: url("~@/assets/solute/smartXycg/banner.png") center no-repeat;
    background-size: cover;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.banner button {
  width: 200px;
  height: 60px;
  background: linear-gradient(135deg, #ff8484 0%, #c61f1f 100%);
  border-radius: 34px;
  font-size: 16px;
  color: #fff;
  border: 0;
}
.banner button:hover {
  box-shadow: 0px 8px 32px -6px rgba(131, 7, 7, 0.4);
  cursor: pointer;
}
.descH4 {
  font-size: 36px;
  font-weight: 500;
  color: #222222;
  padding: 60px 0 20px;
}

.systemArchitecture{
  width: 100%;
  height: 750px;
  background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.systemValue{
     width: 100%;
height: 576px;
background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
display: flex;
flex-direction: column;
align-items: center;
}

.valueContent{
  width: 1200px;
  display: flex;
  flex-wrap: wrap;
  padding-top: 40px;
}
.valueItem{
  position: relative;
  top: 0;
  left: 0;
  width: 580px;
  height: 148px;
  margin-right: 40px;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 40px;
  padding: 40px;
  cursor: pointer;
}
.valueItemMask{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}
.valueItem:nth-child(2n){
  margin-right: 0px;
}
.valueLeft{
  width: 68px;
}
.valueLeftHover{
  width: 68px;
  display: none;
}
.valueRight{
  font-size: 18px;
  font-weight: 400;
  color: #444444;
  line-height: 28px;
  margin-left: 20px;
}
.valueItem:hover{
  box-shadow: 0px 8px 20px 0px rgba(173,0,0,0.3);
  background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
  .valueItemMask{
    display: block;
    background: url('~@/assets/hoverBg.png') center no-repeat;
    background-position: top left;
  }
  .valueRight{
    color: #fff;
  }
  .valueLeftHover{
    display: block;
  }
  .valueLeft{
    display: none;
  }
}

.ywtd{
  width: 100%;
  height: 750px;
  background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.yetdTitleDes{
  width: 900px;
  font-size: 16px;
  font-weight: 400;
  color: #444444;
  line-height: 28px;
  text-align: center;
  margin-bottom: 20px;
}
.yetdContent{
  width: 1200px;
  margin-top:40px;
  display: flex;
  flex-wrap: wrap;
}
.yetdItem{
  position: relative;
  top: 0;
  left: 0;
  width: 580px;
  // min-height: 180px;
  // max-height: 220px;
  margin-right: 40px;
  margin-bottom: 40px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid rgba(220,223,230,0.5);
  padding: 31px 40px;
  box-sizing: border-box;
  cursor: pointer;
}
.valueItemMask{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}
.yetdItem:nth-child(2n){
  margin-right: 0px;
}
.yetdLine{
  width: 30px;
  height: 4px;
  background: #D70C0C;
  margin: 14px 0 16px;
}
.yetdTitle{
  font-size: 24px;
  font-weight: 500;
  color: #222222;
  line-height: 36px;
}
.yetdCon{
  font-size: 16px;
  font-weight: 400;
  color: #444444;
  line-height: 24px;
}
.yetdItem:hover{
   box-shadow: 0px 8px 20px 0px rgba(173,0,0,0.3);
  background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
  border: 0px;
  .valueItemMask{
    display: block;
    background: url('~@/assets/hoverBg.png') center no-repeat;
    background-position: top left;
  }
  .yetdTitle,.yetdCon{
    color: #fff;
  }
  .yetdLine{
    background-color: #fff;
  }
}

.solute{
  width: 100%;
  background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
}

.applicationCase{
  background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.appCaseCon{
  height: 490px;
}
.caseContent{
  width: 1200px;
  min-height: 400px;
  padding-top: 60px;
}
.caseContent{
  &::v-deep{
    .el-carousel__container{
      height: 330px;
    }
  }
}

.caseItem{
  width: 100%;
  height: 330px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #FFFFFF;
  border-radius: 8px;
  border: 1px solid rgba(211,218,228,0.5);
  cursor: pointer;
}
.caseItem:hover{
  box-shadow: 0px 4px 20px -4px rgba(0,0,0,0.15);
}
.caseLeft{
  width: 460px;
  height: 100%;
}
.caseRight{
  width: 740px;
  height: 100%;
  padding: 40px;
  box-sizing: border-box;
}
.appCaseCon{
  &::v-deep {
      .el-carousel__button {
      width: 20px;
      height: 6px;
      background: #d70c0c !important;
      border-radius: 3px;
    }
    .el-carousel__indicators--outside{
      margin-top: 30px;
    }
  }
}
.caseTitle{
  font-size: 24px;
  font-weight: 500;
  color: #222222;
  line-height: 28px;
}
.caseRIghtCon{
  font-size: 16px;
  font-weight: 400;
  color: #444444;
  line-height: 26px;
  padding-top: 12px;
}
</style>
